//
// Plugins / Dropzone
// --------------------------------------------------


// Fallback
.dz-browser-not-supported {
  .dz-message { display: none !important; }

  &.dropzone-box {
    width: auto !important;
    min-height: auto !important;
    padding: 0 !important;

    cursor: default !important;
    transition: none;

    border: none !important;
    border-radius: 0 !important;
  }
}

.dropzone-box {
  position: relative;

  width: 100%;
  min-height: $dropzone-box-min-height;
  padding: 0 $dropzone-box-padding $dropzone-box-padding 0;

  cursor: pointer;

  border: 3px dashed $dropzone-border-color;
  border-radius: $border-radius-base;
}


// Default message
//

.dz-default.dz-message {
  position: absolute;
  top: 50%;
  right: $dropzone-box-padding;
  left: $dropzone-box-padding;

  padding-left: $dropzone-upload-icon-size + 16px;

  transform: translateY(-50%) scale(1);

  font-size: 20px;
  font-weight: 600;
  line-height: 22px;

  .dz-text-small {
    font-size: 14px;
    font-weight: 400;
  }

  .dz-started & { display: none; }
}

// Icon made by Yannick(http://www.flaticon.com/authors/yannick) from www.flaticon.com
// License: CC BY 3.0
.dz-upload-icon {
  position: absolute;
  top: 50%;
  left: 0;

  width: $dropzone-upload-icon-size;
  height: $dropzone-upload-icon-size;

  transform: translateY(-50%) scale(1);

  border-radius: $border-radius-base;
  background-image: $dropzone-upload-icon;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ($dropzone-upload-icon-size / 2) ($dropzone-upload-icon-size / 2);
}

// Preview
//

.dz-preview {
  box-sizing: content-box;
  margin: $dropzone-box-padding 0 0 $dropzone-box-padding;
  padding: 8px $dropzone-preview-padding $dropzone-preview-padding $dropzone-preview-padding;

  cursor: default;
  vertical-align: top;

  border: 1px solid $dropzone-border-color;
}


// File info
//

.dz-filename { overflow: hidden }

.dz-size {
  padding-bottom: 8px;

  color: $text-muted;

  font-size: 11px;
  font-style: italic;
}


// Thumbnail
//

.dz-thumbnail {
  position: relative;

  box-sizing: content-box;
  height: $dropzone-thumbnail-height;
  margin: 0 (-$dropzone-preview-padding);
  padding: $dropzone-preview-padding;

  text-align: center;

  border-top: 1px solid $dropzone-border-color;
  border-bottom: 1px solid $dropzone-border-color;
  background: $dropzone-thumbnail-bg;

  > img,
  .dz-nopreview {
    position: relative;
    top: 50%;

    display: block;

    margin: 0 auto;

    transform: translateY(-50%) scale(1);
  }

  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.dz-nopreview {
  text-transform: uppercase;

  color: $text-muted;

  font-size: 11px;
  font-weight: 600;

  .dz-thumbnail img[src] ~ & { display: none; }
}


// Progress
//

.dz-preview .progress,
.dz-preview .progess-bar {
  height: $dropzone-progress-height;
}

.dz-preview .progress { margin: 8px 0 0 0 }


// Remove link
//

.dz-remove {
  display: block;

  margin: 8px (-$dropzone-preview-padding) (-$dropzone-preview-padding) (-$dropzone-preview-padding);
  padding: 8px 0;

  text-align: center;
  text-transform: uppercase;

  color: $text-color;
  border-top: 1px solid $dropzone-border-color;

  font-size: 10px;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;

    color: #fff;
    border-top-color: transparent;
    background: $brand-danger;
  }
}


// States
//

.dz-error-mark,
.dz-success-mark,
.dz-error-message {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;

  display: none;

  background-color: $dropzone-overlay-bg;
}

.dz-error-mark,
.dz-success-mark {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $dropzone-icon-size $dropzone-icon-size;
}

.dz-error-mark { background-image: $icon-danger }
.dz-success-mark { background-image: $icon-success }

.dz-error-message {
  overflow: auto;

  padding: 12px;

  text-align: center;

  color: #fff;

  font-weight: 600;
}

// Error state
.dz-error {
  .dz-error-mark { display: block; }
  .dz-error-message { display: none; }

  &:hover {
    .dz-error-mark { display: none; }
    .dz-error-message { display: block; }
  }
}

// Success state
.dz-success .dz-success-mark { display: block }


@media (min-width: $screen-sm-min) {
  .dz-default.dz-message {
    right: auto;
    left: 50%;

    transform: translate(-50%, -50%) scale(1);
  }

  .dz-preview {
    display: inline-block;

    width: $dropzone-thumbnail-width;
  }

  .dz-thumbnail { width: $dropzone-thumbnail-width; }

  html[dir="rtl"] .dz-default.dz-message {
    transform: translate(50%, -50%) scale(1);
  }
}
