.btn {
    &:hover {
        transition: background-color 0.3s;
    }
}

.page-header {
    border: none!important;
    margin-bottom: 0!important;
    .fa {
        padding-right: 10px;
    }
    h1 {
        font-size: 22px;
        font-weight: normal;
    }
}

.device {
    background-color: #fff;
    padding: 25px;
    min-height: 300px;
    @extend .box-shadow;
    overflow: hidden;
    margin-bottom: 20px;
    &__title {
        font-size: 22px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0,0,0,.07);
    }
    &__dots {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        span {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $gray;
            display: block;
            vertical-align: middle;
            float: left;
            margin-left: 2px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .month {
        color: $gray;
        font-size: 16px;
    }

    &__availability {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: $gray;
        float: right;
        padding-top: 10px;
        &:after {
            content: "";
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: 2px;
            border: 2px solid #fff;
            background: $green;
            @extend .box-shadow;
            margin-left: 5px;
        }
    }

    &__img {
        margin-top: 40px;
        img {
            width: 100%;
        }
    }

    &__detailsContainer {
        padding-right: 0;
        margin-top: 80px;
    }

    &__btn {
        text-align: right;
    }

    &--ordered  {
        background: #dedede;
        opacity: 0.5;
        box-shadow: rgba(0,0,0,.4) 0 0 8px;
        .device__availability:after {
            background: $orange;
        }
        .btn {
            border-color: $gray-dark!important;
            color: $gray-dark!important;
            &:hover, &:focus {
                background: none!important;
                cursor: pointer!important;
            }
        }
    }

    &__details {
        &--label {
            color: $gray;
            font-size: 20px;

        }
        &--value {
            color: $blue;
            font-size: 28px;
        }
    }
}

.nyDevices {
   margin-bottom: 15px;
}

.availableProducts{
    .device__title {
        color: $blue;
    }
}