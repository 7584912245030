//
// Plugins / C3
// --------------------------------------------------

// Chart

.c3 {
  svg {
    font-size: 10px;
    -webkit-tap-highlight-color: transparent;
  }

  path,
  line {
    fill: none;
    stroke: $c3-axis-color;
  }

  text {
    fill: $c3-text-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc {
  path { stroke: $c3-arcs-stroke; }

  text {
    fill: #fff;
    font-size: 11px;
  }
}

// Axis
// Grid

.c3-grid {
  line { stroke: $c3-guide-color; }
  text { fill: $c3-guide-color; }
}

.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}

// Text on Chart

.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

// Line

.c3-line { stroke-width: 1px; }

// Point

.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

// Bar

.c3-bar { stroke-width: 0; }

.c3-bar._expanded_ { fill-opacity: 0.75; }

// Focus

.c3-target.c3-focused { opacity: 1; }

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step { stroke-width: 2px; }

.c3-target.c3-defocused { opacity: 0.3 !important; }

// Region

.c3-region {
  fill: steelblue;
  fill-opacity: .1;
}

// Brush

.c3-brush .extent { fill-opacity: .1; }

// Select - Drag
// Legend

.c3-legend-item { font-size: 12px; }
.c3-legend-item-hidden { opacity: 0.15; }

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}

// Title

.c3-title { font: 14px sans-serif; }

// Tooltip

.c3-tooltip-container {
  z-index: 10;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
  padding: 5px;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  background: none;

  &,
  tr,
  th,
  td {
    border: none;
    color: #FFF;
  }

  th {
    font-size: 12px;
    padding: 1px 5px;
    text-align: left;
  }

  td {
    font-size: 11px;
    padding: 0 6px;

    &.value {
      text-align: right;
    }

    > span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  }
}

// Area

.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}

// Arc

.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs {
  .c3-chart-arcs-background {
    fill: $c3-arcs-background;
    stroke: none;
  }

  .c3-chart-arcs-gauge-unit {
    fill: $c3-text-color;
    font-size: 16px;
  }

  .c3-chart-arcs-gauge-max { fill: $c3-text-color; }
  .c3-chart-arcs-gauge-min { fill: $c3-text-color; }
  .c3-chart-arc .c3-gauge-value { fill: $c3-text-color; }
}
