.panel-heading {
  padding: 10px;
}

.panel-body {
  padding: 20px 10px;
}

.form-control,
.input-group-addon {
  padding: 4px;
}
.form-group.row {
  margin-right: 0;
}