//
// Plugins / Quick[select]
// --------------------------------------------------

.quickselect__more {
  position: relative;
}

.quickselect__select {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  color: #333;
  border: 0;

  -webkit-appearance: menulist-button; /* Fix for Chrome's select box height bug */
}

.quickselect__select:not(:disabled) {
  cursor: pointer;
}

.quickselect__select.quickselect__hidden {
  position: absolute;

  visibility: hidden;

  width: 0;
  height: 0;
  padding: 0;
}

.quickselect__more--label:after {
  display: inline-block;

  width: 0;
  height: 0;
  margin-left: .3076923076em;

  content: "";
  vertical-align: middle;

  @include arrow-down(.3em);
}
