
.deviceDetail {
    display: flex;
    &__block {
        @extend .box-shadow;
        background: #fff;
        padding: 0;
    }
    &__right {
        width: calc(70% - 20px);
        float: left;
    }
    &__details {
        margin-left: 20px;
        width: 30%;
    }
    &__header {
        border-bottom: 1px solid $gray-light;
        h2 {
            margin: 0;
            padding: 20px 25px;
            font-size: 22px;

            font-weight: normal;
        }
        .device__availability {
            padding-top: 5px;
            font-size: 16px;
            &:after {
                width: 20px;
                height: 20px;
                top: 5px;
            }
        }
    }
    &__content {
        position: relative;
        padding: 25px 20px;
        p {
            color: #000;
            font-size: 16px;

        }
        img {
            position: absolute;
            left: 45px;
            width: 400px;
            top: 25px;
        }

        figure {
            width: 500px;
            margin: 0 auto;
        }
    }
    &__counter {
        position: relative;
        width: 500px;
        margin: 30px auto 0 auto;
    }
    &__label {
        color: $gray;
        font-size: 18px;
    }
    &__senzor {
        text-align: center;
        margin-top: 45px;
        &--label {
            color: $gray;
            font-size: 30px;
        }
        &--value {
            color: $blue;
            font-size: 68px;
        }
    }
    .chart {
        position: relative;
        &__number {
            position: absolute;
            top: 183px;
            left: 205px;
            z-index: 1;
            font-size: 50px;
        }
    }
}

.device__availability {
    &--offline {
        &:after {
            background: red!important;
        }
    }
}
