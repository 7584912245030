//
// Plugins / Ion.RangeSlider
// --------------------------------------------------

html[dir="rtl"] .irs,
html[dir="rtl"] .irs * {
  /*@noflip*/ direction: ltr !important;
}

.irs {
  position: relative;

  display: block;

  user-select: none;

  -webkit-touch-callout: none;
}

.irs-line {
  position: relative;

  display: block;
  overflow: hidden;

  outline: none !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  top: 0;

  display: block;
}

.irs-line-left {
  /*@noflip*/ left: 0;

  width: 11%;
}

.irs-line-mid {
  /*@noflip*/ left: 9%;

  width: 82%;
}

.irs-line-right {
  /*@noflip*/ right: 0;

  width: 11%;
}

.irs-bar {
  position: absolute;
  /*@noflip*/ left: 0;

  display: block;

  width: 0;
}

.irs-bar-edge {
  position: absolute;
  top: 0;
  /*@noflip*/ left: 0;

  display: block;
}

.irs-shadow {
  position: absolute;
  /*@noflip*/ left: 0;

  display: none;

  width: 0;
}

.irs-slider {
  position: absolute;
  z-index: 1;

  display: block;

  cursor: default;

  &.type_last { z-index: 2; }
}


.irs-min {
  position: absolute;
  /*@noflip*/ left: 0;

  display: block;

  cursor: default;
}

.irs-max {
  position: absolute;
  /*@noflip*/ right: 0;

  display: block;

  cursor: default;
}

.irs-from, .irs-to, .irs-single {
  position: absolute;
  top: 0;
  /*@noflip*/ left: 0;

  display: block;

  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  bottom: 0;
  /*@noflip*/ left: 0;

  display: none;

  width: 100%;
  height: 20px;

  .irs-with-grid & { display: block; }
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  /*@noflip*/ left: 0;

  width: 1px;
  height: 8px;

  background: #000;

  &.small { height: 4px; }
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  /*@noflip*/ left: 0;

  padding: 0 3px;

  text-align: center;
  white-space: nowrap;

  color: #000;

  font-size: 9px;
  line-height: 9px;
}

.irs-disable-mask {
  position: absolute;
  z-index: 2;
  top: 0;
  /*@noflip*/ left: -1%;

  display: block;

  width: 102%;
  height: 100%;

  cursor: default;

  background: rgba(0, 0, 0, 0);
}

.irs-disabled { opacity: 0.4; }

.irs-hidden-input {
  position: absolute !important;
  z-index: -9999 !important;
  top: 0 !important;
  /*@noflip*/ left: 0 !important;

  display: block !important;
  overflow: hidden;

  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;

  border-style: solid !important;
  border-color: transparent !important;
  outline: none !important;
  background: none !important;

  font-size: 0 !important;
  line-height: 0 !important;
}


// Default style
//

.irs {
  height: 28px + $ionrangeslider-slider-height;
}
.irs-with-grid {
  height: 46px + $ionrangeslider-slider-height;
}
.irs-line {
  top: 25px;

  height: $ionrangeslider-bar-height;
}

.irs-line-left {
  height: $ionrangeslider-bar-height;

  background: $ionrangeslider-line-color;

  /*@noflip*/ border-bottom-left-radius: 999px;
  /*@noflip*/ border-top-left-radius: 999px;
}

.irs-line-mid {
  height: $ionrangeslider-bar-height;

  background: $ionrangeslider-line-color;
}

.irs-line-right {
  height: $ionrangeslider-bar-height;

  background: $ionrangeslider-line-color;

  /*@noflip*/ border-bottom-right-radius: 999px;
  /*@noflip*/ border-top-right-radius: 999px;
}

.irs-bar {
  top: 25px;

  height: $ionrangeslider-bar-height;
}

.irs-bar-edge {
  top: 25px;

  width: 9px;
  height: $ionrangeslider-bar-height;

  /*@noflip*/ border-bottom-left-radius: 999px;
  /*@noflip*/ border-top-left-radius: 999px;
}

.irs-shadow {
  top: 25px + $ionrangeslider-bar-height - 3px;

  height: 3px;

  background: $ionrangeslider-shadow-color;
}

.irs-slider {
  top: 25px - ($ionrangeslider-slider-height - $ionrangeslider-bar-height) / 2;

  width: 16px;
  height: $ionrangeslider-slider-height;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    /*@noflip*/ left: 7px;

    display: block;

    width: 2px;

    content: '';
  }
}

.irs-min,
.irs-max {
  top: 0;

  padding: 1px 3px;

  color: $ionrangeslider-min-max-color;
  border-radius: $border-radius-base;
  background: $ionrangeslider-min-max-bg;
  text-shadow: none;

  font-size: 10px;
  line-height: 1.333;
}

.irs-from,
.irs-to,
.irs-single {
  padding: 1px 5px;

  border-radius: $border-radius-base;
  text-shadow: none;

  font-size: 10px;
  line-height: 1.333;

  &:after {
    position: absolute;
    bottom: -6px;
    /*@noflip*/ left: 50%;

    display: block;
    overflow: hidden;

    width: 0; height: 0;
    /*@noflip*/ margin-left: -3px;

    content: "";

    border: 3px solid transparent;
  }
}

.irs-grid-pol { background: $ionrangeslider-grid-color; }
.irs-grid-text { color: $ionrangeslider-grid-text-color; }


// Bullet style
//

.px-irs-bullet {
  .irs { height: 28px + $ionrangeslider-bullet-button-size; }
  .irs-with-grid { height: 46px + $ionrangeslider-bullet-button-size; }

  .irs-line,
  .irs-line-left,
  .irs-line-mid,
  .irs-line-right,
  .irs-bar,
  .irs-bar-edge {
    height: $ionrangeslider-bullet-bar-height;
  }

  .irs-slider {
    top: 25px - ($ionrangeslider-bullet-button-size - $ionrangeslider-bullet-bar-height) / 2;

    height: $ionrangeslider-bullet-button-size;

    &:before {
      position: absolute;
      top: 1px;
      /*@noflip*/ left: ((16px - $ionrangeslider-bullet-button-size) / 2) + 1px;

      display: block;

      width: $ionrangeslider-bullet-button-size - 2px;
      height: $ionrangeslider-bullet-button-size - 2px;

      content: '';

      border-radius: 999px;
      background: $ionrangeslider-bullet-button-color;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, .15);
    }

    &:after {
      top: ($ionrangeslider-bullet-button-size - $ionrangeslider-bullet-button-point-size) / 2;
      bottom: auto;
      /*@noflip*/ left: (16px - $ionrangeslider-bullet-button-point-size) / 2;

      width: $ionrangeslider-bullet-button-point-size;
      height: $ionrangeslider-bullet-button-point-size;

      border-radius: 999px;
    }
  }

  .irs-shadow { top: 25px + $ionrangeslider-bullet-bar-height - 3px; }

  .irs-from:after,
  .irs-to:after,
  .irs-single:after {
    display: none;
  }
}


// Colors
//

@include ionrangeslider-base($ionrangeslider-default-color, $ionrangeslider-default-bg);
@include ionrangeslider-variant(info, #fff, $brand-info);
@include ionrangeslider-variant(success, #fff, $brand-success);
@include ionrangeslider-variant(warning, #fff, $brand-warning);
@include ionrangeslider-variant(danger, #fff, $brand-danger);
